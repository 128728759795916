@import url("https://fonts.googleapis.com/css?family=Englebert|Kelly+Slab|Pacifico|Share+Tech+Mono&display=swap");

:root {
    --color-text: #212121; /*  Text Main */
    --color-back: #f5f5f5; /*  Body BG, Inputs BG */
    --color-grey: #bdbdbd; /*  Dark Triangle, Borders, Dividers */
    --color-light: #ffffff; /* White Elements */
    --color-primary: #c3dceb;
    --color-dangerBack: #faeaea;
    --color-dangerText: #721c24;
}

:root.themeDark {
    --color-text: #eeeeee; /*  Text Main */
    --color-back: #282828; /*  Body BG, Inputs BG */
    --color-grey: #525252; /*  Dark Triangle, Borders, Dividers */
    --color-light: #363636; /* White Elements */
    --color-primary: #2f4f4f;
    --color-dangerBack: #3a2a2a;
    --color-dangerText: #faeaea;
}

:root.themeDark .adapt {
    filter: invert(1) !important;
}

html {
    /* Theme Font Families */
    body {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-family: "Kelly Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        code {
            font-family: "Share Tech Mono", "Source Code Pro", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
        }

        p {
            line-height: 1.75;
            letter-spacing: 1px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .btn {
            font-family: "Englebert", sans-serif;
            letter-spacing: 1px;
        }

        .fontCursive {
            font-family: "Pacifico", cursive;
        }
        .fontHeading {
            font-family: "Englebert", sans-serif;
            letter-spacing: 1px;
        }
        .fontRegular {
            font-family: "Kelly Slab", sans-serif;
            letter-spacing: 1px;
        }

        .fontXXL {
            font-size: 130%;
        }
        .fontXL {
            font-size: 120%;
        }
        .fontLarge {
            font-size: 110%;
        }
        .fontNormal {
            font-size: 100%;
        }
        .fontSmall {
            font-size: 90%;
        }
        .fontXS {
            font-size: 80%;
        }
        .fontXXS {
            font-size: 70%;
        }
    }

    /* Theme Color Schemes */
    ::-moz-selection {
        color: var(--color-text);
        background-color: var(--color-primary);
    }

    ::selection {
        color: var(--color-text);
        background-color: var(--color-primary);
    }

    ::-webkit-input-placeholder {
        opacity: 0.2;
        color: var(--color-text);
    }

    :-ms-input-placeholder {
        opacity: 0.2;
        color: var(--color-text);
    }

    ::-moz-placeholder {
        opacity: 0.2;
        color: var(--color-text);
    }

    :-moz-placeholder {
        opacity: 0.2;
        color: var(--color-text);
    }

    body {
        .text-primary,
        .bg-primary,
        .btn-primary,
        .btn-primary:hover,
        .nav-link.active,
        .timelineDate,
        .timeline:before,
        .progressLine .fill,
        .progressBullet .bullet.fill {
            background-color: var(--color-primary) !important;
        }

        .btn-primary,
        .btn-primary:hover,
        .progressBullet .bullet {
            border-color: var(--color-primary) !important;
        }

        .bgShape2 polygon {
            fill: var(--color-primary) !important;
        }

        &,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        nav li a,
        .cvLogo,
        .btn-primary,
        .btn-primary:hover,
        .form-control,
        .socialIcons a {
            color: var(--color-text) !important;
        }

        header nav ul > li > a:after,
        .timelineBox:before {
            background-color: var(--color-text) !important;
        }

        header nav ul > li svg *.tone1 {
            fill: var(--color-text) !important;
            opacity: 0.5;
        }

        header nav ul > li svg *.tone2 {
            fill: var(--color-text) !important;
            opacity: 0.8;
        }

        &,
        .form-control,
        .progressLine .progressBar {
            background-color: var(--color-back) !important;
        }

        hr,
        th,
        td,
        blockquote,
        .boxBody,
        .form-control {
            border-color: var(--color-grey) !important;
        }

        .divider {
            background-color: var(--color-grey) !important;
        }

        .bgShape1 polygon {
            fill: var(--color-grey) !important;
        }

        header nav ul > li.active {
            border-color: var(--color-grey) !important;
        }

        .captionTitle,
        header nav ul > li > a:after {
            color: var(--color-light) !important;
        }

        nav,
        .sideBox .card-footer,
        .mainBox .box .boxBody,
        .timelineBox:last-child:after {
            background-color: var(--color-light) !important;
        }

        .form-control.danger,
        .form-control.danger:focus {
            color: var(--color-dangerText) !important;
            border-color: #fababa !important;
            background-color: var(--color-dangerBack) !important;
        }
    }
}

// Styles
.App {
    /* Shapes */
    .bgShape1 {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0.7;
    }

    .bgShape2 {
        position: fixed;
        top: 0;
        right: 0;
        z-index: -1;
    }

    /* Bootstrap 4 Modifications */
    .container {
        position: relative;

        @media (min-width: 1280px) {
            & {
                max-width: 1200px;
            }
        }
    }

    .form-control {
        &:focus {
            box-shadow: none !important;
        }
    }

    .btn {
        transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);

        &:focus {
            box-shadow: none !important;
        }
        &:hover {
            box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
        }

        &:disabled,
        &[disabled] {
            filter: grayscale(0.75);
            pointer-events: none;
        }

        &-default {
            color: #fff;
            background-color: #000;
        }

        &-default:hover {
            color: #fff;
            background-color: #111;
        }

        &Old {
            border-radius: 2rem !important;
            box-shadow: inset 1px 1px 0 1px #fff;

            &:hover {
                box-shadow: inset 0 0 0 0 #fff;
            }
        }

        img {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    .text-muted {
        opacity: 0.5;
    }

    /* Theme Styling */
    .divider {
        width: 100%;
        height: 2px;
        margin: 2rem 0;
        opacity: 0.3;
        border-radius: 50%;
    }

    .iframe-wrap {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            border: 0;
            width: 100%;
            height: 100%;
        }
    }

    /* Header */
    header {
        .cvLogo {
            font-size: 2rem;
        }

        @media (max-width: 992px) {
            .mainBox {
                margin-bottom: 6rem !important;
            }
        }

        nav {
            z-index: 1000;
            margin: 0 1rem;
            box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);

            img.avatar {
                width: 40px;
                height: 40px;
            }

            ul {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    width: 56px;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 4px solid transparent;
                    transition: 200ms;

                    &.active {
                        border-bottom: 4px solid;
                    }

                    a {
                        position: relative;
                        display: inline-block;
                        font-size: 2rem;
                        line-height: 1;
                        text-decoration: none;
                    }
                }
            }

            @media (min-width: 992px) {
                & {
                    box-shadow: none;
                    background-color: transparent !important;
                }

                ul {
                    flex-direction: column;
                    align-items: flex-start;

                    li {
                        border-bottom: none;

                        &.active {
                            border-bottom: none;
                            opacity: 0.8;
                            margin: 2rem 0;
                        }

                        a {
                            &:after {
                                content: attr(title);
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 2;
                                display: block;
                                padding: 0.5rem 1rem;
                                white-space: nowrap;
                                border-radius: 4px;
                                font-size: 0.7rem;
                                letter-spacing: 1px;
                                opacity: 0;
                                visibility: hidden;
                                transform: translateX(-75%) translateY(25%);
                                transition: 200ms ease;
                            }

                            &:hover {
                                &:after {
                                    opacity: 1;
                                    visibility: visible;
                                    transform: translateX(-110%) translateY(25%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /* Header Bulb Theme Changer */
    #bulbSwitch {
        display: flex;
        align-items: center;
        margin-left: auto;

        span {
            margin-right: 0.5rem;
        }
    }

    svg.lightBulb {
        width: 28px;
        height: 28px;
        margin-right: -0.5rem;
        cursor: pointer;

        * {
            transition: 400ms;
        }

        .lightBulbLight * {
            fill: none;
            stroke: #fff;
            stroke-width: 16;
            stroke-linecap: round;
            stroke-linejoin: round;
        }

        &.active .lightBulbLight * {
            stroke: #fc0;
            stroke-width: 40;
        }

        .lightBulbBase {
            fill: #555;
        }

        .lightBulbScrews * {
            fill: none;
            stroke: #ccc;
            stroke-width: 16;
            stroke-linecap: round;
            stroke-linejoin: round;
        }
    }

    ul.socialIcons {
        li:not(:last-child) {
            margin-right: 1rem;
        }

        a {
            font-size: 1.75rem;
            opacity: 0.7;
            text-decoration: none;
            transition: 300ms ease;
        }

        a:hover {
            opacity: 0.4;
            text-decoration: none;
        }
    }

    .sideBox {
        width: 100%;
        box-shadow: -4px 7px 15px 1px rgba(0, 0, 0, 0.2);

        .card,
        .card > * {
            border: 0;
            border-radius: 0;
        }

        @media (min-width: 992px) {
            & {
                float: left;
                width: 296px;
            }
        }

        img.avatar {
            width: 200px;
            height: 200px;
        }
    }

    .mainBox {
        margin-top: 2rem;

        @media (min-width: 992px) {
            & {
                margin-top: 1rem;
                // }

                // body.sideBoxOn & {
                margin-left: 320px;
                // }

                // body.navBoxOn & {
                margin-right: 64px;
            }
        }

        .box {
            margin-bottom: 1.5rem;

            .boxBody {
                position: relative;
                border: 1px solid;
                padding-top: 2rem;
                padding-left: 2rem;
                padding-right: 2rem;
                padding-bottom: 2rem;
                box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem rgba(0, 0, 0, 0.1);
            }

            h2.title {
                margin-bottom: 2rem;
                font-weight: bold;
                letter-spacing: 1.5px;
            }

            h3.title {
                margin-bottom: 1rem;
                opacity: 0.9;
                letter-spacing: 1.5px;
            }

            h4.title {
                opacity: 0.9;
            }

            .signature {
                width: auto;
                height: 60px;
                margin-top: -1rem;
                margin-right: 1rem;
            }

            #portfolio ul.nav li {
                cursor: pointer;
            }
        }
    }

    .progressBullet {
        white-space: nowrap;
        line-height: 1rem;

        .bullet {
            width: 1rem;
            height: 1rem;
            display: inline-block;
            margin-right: 0.3rem;
            border: 0.2rem solid;
            border-radius: 50%;
            vertical-align: middle;
        }
    }

    .progressLine {
        position: relative;
        margin-bottom: 1.5rem;

        .progressText {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 0.9rem;
            opacity: 0.8;
        }

        .progressBar {
            position: relative;
            width: 100%;
            height: 5px;
            overflow: hidden;
            border-radius: 5px;

            .fill {
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: inherit;
                border-radius: inherit;
            }
        }
    }

    .timeline {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 1.5rem;
            left: 0;
            bottom: 0;
            width: 1px;
            opacity: 0.5;
        }

        @media (min-width: 768px) {
            & {
                margin-left: 12rem;
            }

            &:before {
                top: 0.5rem;
            }
        }

        .timelineBox {
            position: relative;
            padding-left: 1rem;
            margin-bottom: 3rem;

            &:before {
                content: "";
                position: absolute;
                top: 1rem;
                left: -1px;
                z-index: 1;
                width: 0.25rem;
                height: 0.25rem;
                border-radius: 50%;
            }

            &:last-child:after {
                content: "";
                position: absolute;
                top: 1rem;
                left: 0;
                bottom: 0;
                width: 1px;
            }

            h3 {
                margin-top: 1rem;
                margin-bottom: 0.5rem;
                font-size: 1.1rem;
                font-weight: bold;
                letter-spacing: 1.5px;
            }

            @media (min-width: 768px) {
                & {
                    padding-right: 5rem;
                }

                &:before {
                    top: 0.5rem;
                }

                h3 {
                    font-size: 1.3rem;
                    margin-top: 0;
                }
            }
        }

        .timelineDate {
            position: relative;
            display: inline-block;
            width: auto;
            padding: 0.5rem 1rem;
            padding-left: 1.5rem;
            font-size: 0.875rem;
            clip-path: polygon(10% 0, 100% 0%, 100% 100%, 10% 100%, 0% 50%);

            b {
                opacity: 0.8;
                font-weight: bold;
            }

            @media (min-width: 768px) {
                & {
                    position: absolute;
                    left: auto;
                    right: 100%;
                    width: 10rem;
                    margin-top: -0.5rem;
                    margin-right: 0.5rem;
                    padding-left: 1rem;
                    padding-right: 1.5rem;
                    text-align: center;
                    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
                }
            }
        }
    }

    .gridItems {
        position: relative;
        margin: 0 -0.75rem;

        .gridItem {
            position: absolute;
            padding: 0.25rem;
            will-change: transform, width, height, opacity;
        }

        .gridBody {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 2px;
            text-decoration: none;
            background-size: cover;
            background-color: #555;
            background-position: center;
            box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
            cursor: pointer;

            .gridImage {
                position: relative;
                z-index: 1;
                margin: 0;
            }

            .gridCaption {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                padding: 2rem;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.8);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                opacity: 0;
                visibility: hidden;
                transform: scale(2);
                transition: 300ms ease;

                .captionTitle {
                    font-size: 1.3rem;
                    color: inherit;
                    margin-top: 0;
                    transform: translateY(-2rem);
                    transition: transform 0.4s ease-in-out;
                }

                .captionText {
                    max-height: 80px;
                    font-size: 0.9rem;
                    margin-bottom: 2rem;
                }

                .captionBtn {
                    transform: translateY(2rem);
                    transition: transform 0.4s ease-in-out;
                }
            }

            &:hover .gridCaption {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
            }

            &:hover .gridCaption .captionBtn,
            &:hover .gridCaption .captionTitle {
                transform: translateY(0);
            }
        }
    }

    .refBox {
        position: relative;
        min-height: 5rem;
        margin-bottom: 3rem;
    }

    #map {
        filter: grayscale(0.5);
    }

    @for $i from 0 through 100 {
        .opacity#{$i * 1} {
            opacity: #{$i / 100} !important;
        }
    }

    // Animation Support
    .animate {
        &.fadeIn {
            animation-delay: 0s;
            animation-duration: 250ms;
            animation-fill-mode: both;
            animation-name: fadeIn;

            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }

        &.fadeInUps {
            animation-delay: 0s;
            animation-duration: 450ms;
            animation-fill-mode: both;
            animation-name: fadeInUp;

            @keyframes fadeInUp {
                0% {
                    opacity: 0;
                    transform: translateY(20px);
                }

                100% {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        &.fadeOutDown {
            animation-delay: 0s;
            animation-duration: 450ms;
            animation-fill-mode: both;
            animation-name: fadeOutDown;

            @keyframes fadeOutDown {
                0% {
                    opacity: 1;
                    transform: translateY(0);
                }

                100% {
                    opacity: 0;
                    transform: translateY(20px);
                }
            }
        }

        @for $i from 00 through 99 {
            .fadeIn,
            .fadeInUp,
            .fadeOutDown {
                &.delay-#{$i * 1} {
                    animation-delay: #{$i * 0.1}s !important;
                }
            }
        }
    }

    .fadeInUps {
        opacity: 0;
        visibility: hidden;
        transform: translate3d(0, 200px, 0) scale3d(1, 1, 1);
        transition: transform 300ms ease-out, opacity 300ms ease-out, visibility 300ms ease-out;

        &.animate {
            opacity: 1;
            visibility: visible;
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
            transition: transform 300ms ease-out, opacity 300ms ease-out, visibility 300ms ease-out;
        }
    }
}
